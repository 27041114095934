<div id="{{'carouselExampleIndicators'+random}}" data-interval="false" interval=false class="carousel slide"
  style="left:-23px">
  <ol class="carousel-indicators" style="bottom: -30%;" *ngIf="number.length > 1">
    <li [attr.data-target]="'#carouselExampleIndicators'+random" [attr.data-slide-to]="i" *ngFor="let i of number"
      [className]="i == 0 ? 'active' : ''"></li>
  </ol>
  <div class="carousel-inner">
    <div *ngFor="let i of number" [className]="i == 0 ? 'carousel-item active' : 'carousel-item'">
      <div class="d-flex">
        <div *ngFor="let addon of addons | slice: i*5 : (i+1)*5" class="addon">
          <div class="card shadow card-div" (click)="onclick(addon)">
            <div *ngIf="addon.isNew" class="new px-2" data-toggle="tooltip" title="New">
              New
            </div>
            <span class="expired px-2" data-toggle="tooltip" *ngIf="addon.expired === true">License Expired</span>
            <span class="expiresIn px-2" data-toggle="tooltip" *ngIf="addon.expired === false && addon.expiresIn <= 15 && addon.expiresIn != 0">Expires in {{ addon.expiresIn }} day(s)</span>
            <span class="expiresIn px-2" data-toggle="tooltip" *ngIf="addon.expired === false && addon.expiresIn == 0">Expires Today</span>
            
            <i *ngIf="addon.purchased" class="installed far fa-check-circle" data-toggle="tooltip"
              title="Installed"></i>

            <div class="card-img"><img src="./assets/addons/{{addon.productCode}}.jpg"
                onerror="this.onerror=null;this.src='./assets/addons/addon.jpg';" width="135" class="card-img-top">
            </div>
            <div class="card-content">
              <h6 class="card-title"><span data-toggle="tooltip" title="{{addon.displayName}}"> {{addon.displayName === 'ONDC' ? 'ONDC Protocol' : addon.displayName}} </span><span *ngIf="addon.isTrending"
                  class="trending fas fa-fire-alt" data-toggle="tooltip" title="Trending"></span></h6>
              <span class="card-subtitle text-muted">{{addon.addonType}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="{{'#carouselExampleIndicators'+random}}" role="button" data-slide="prev"
    *ngIf="number.length > 1">
    <div class="prev-icon-material"><span class="material-icons" aria-hidden="true">arrow_back_ios</span></div>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="{{'#carouselExampleIndicators'+random}}" role="button" data-slide="next"
    *ngIf="number.length > 1">
    <div class="prev-icon-material"><span class="material-icons" aria-hidden="true">arrow_forward_ios</span></div>
    <span class="sr-only">Next</span>
  </a>
</div>