import {Component, AfterViewChecked} from '@angular/core';
import { UiCommonService } from './shared/service/uiCommon.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements AfterViewChecked {

  constructor(public uiCommonServ: UiCommonService,
    private cdRef: ChangeDetectorRef) {
  }

  ngAfterViewChecked() {
    if(document.getElementById("insertImage-")) {
      document.getElementById("insertImage-").style.display = "none";
      document.getElementById("insertVideo-").style.display = "none";
      document.getElementById("toggleEditorMode-").style.display = "none";
      document.getElementById("fontSelector-").style.display = "none";
      document.getElementById("heading-").style.display = "none";
    }
    this.cdRef.detectChanges();
    
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }
}
