import { Component, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NotificationService } from '../../core/services/notification.service';
import Swal from 'sweetalert2';
import { MenuService } from '../../core/services/menu.service';
import { LoginService } from '../login/login.service';
import { MenuMaster } from '../../core/models';
import { AmazonConfigService } from '../integration/ecommerce/pages/configuration/amazon-config/amazon-config.service';
import { UiCommonService } from '../../shared/service/uiCommon.service';
import { environment } from '../../../environments/environment';
import {SharedService} from '../../shared/service/shared.service';
import { concatMap } from 'rxjs/operators';
import { PaymentService } from '../integration/payment/payment.service';
import { EcommerceService } from '../integration/ecommerce/ecommerce.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  uName: string;
  addons: any;
  menuListArray: Array<MenuMaster> = [];
  currentPlanDetails: any;
  modalPopupClose = false;
  installedAddons = 0;
  src = './assets/redirection/Redirection.jpg';
  link = environment.link742;
  timeout: any;
  countTimer = 0;
  expiredAddons: any;
  faSkews = ['535','768','926'];

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notify: NotificationService,
    private menuService: MenuService,
    private amazonConfigService: AmazonConfigService,
    private loginService: LoginService,
    private uiCommonService: UiCommonService,
    private sharedService: SharedService,
    private paymentService: PaymentService,
    private ecommerceService: EcommerceService
  ) {
  }


  ngOnInit() {
    this.uName = this.authService.getUserName();
    // const header = new HttpHeaders().set('Content-Type', 'application/json');
    const headers = new HttpHeaders().set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    let formObj = {
      "loginMailId": this.authService.getEmailId()
    }
    this.loginService.getCsrfToken().pipe(
      concatMap(csrfResponse => {
        console.log('CSRF response from Accounts' , csrfResponse);
        return this.paymentService.getCsrfToken();
      }),
      concatMap(csrfResponse => {
        console.log('CSRF response from Payment' , csrfResponse);
        return this.ecommerceService.getCsrfToken();
      }),
      concatMap(csrfResponse => {
        console.log('CSRF response from Ecommerce', csrfResponse);
        return this.loginService.getAddOnForHomePage(formObj);
      })
    ).subscribe(
      res => {
        if (res.status) {
          this.addons = res;
          // this.authService.setAddonDetails(res);
          sessionStorage.setItem('addonDetails' ,JSON.stringify(res))
          this.expiredAddons = this.addons.installed.filter( res => res.expiresIn <= 15 )
        } else {
          this.notify.showInfo('Info', res.message);
        }
      },
      (err) => {
      });
    // this.onGetCurrentPlanDetails();
  }

  expiredLicenseCheck(addon: any) {
    if (addon.expired === true) {
      (Swal as any).fire({
        title: 'License Expired',
        text: 'Your '+addon.displayName+' license is expired, Kindly renew it.',
        type: 'error',
        footer: '<span>For renewal, Kindly contact Gofrugal customer success team <p style="margin-left: 70px;"><i style="font-size:15px" class="fa">&#xf0e0;</i>&nbsp;customersuccess-team@gofrugal.com</p></span>',
        showConfirmButton: true,
        allowOutsideClick: false
    })} else {
      this.onClick(addon);
    }
  }
  
  public async onClick(addon: any) {
    if (addon.productCode === '744') {
      // this.router.navigate([]).then(result => {  window.open(environment.link742, '_blank'); });
      this.installedAddons  = 0;
      if (this.addons.installed) {
        this.addons.installed.forEach( x => {
          if (x.productCode === '744') {
            this.installedAddons += 15;
          }
        });
        switch (this.installedAddons) {
          case 44:
            this.src = './assets/redirection/45.svg';
            this.link = environment.link742;
            break;
          case 29:
            this.src = './assets/redirection/29.svg';
            this.link = environment.link742;
            break;
          case 28:
            this.src = './assets/redirection/28.svg';
            this.link = environment.link742;
            break;
          case 13:
            this.src = './assets/redirection/13.svg';
            this.link = environment.link742;
            break;
          case 31:
            this.src = './assets/redirection/31.svg';
            this.link = environment.link538;
            break;
          case 16:
            this.src = './assets/redirection/16.svg';
            this.link = environment.link538;
            break;
          case 15:
            this.src = './assets/redirection/15.svg';
            this.link = environment.link744;
            break;
          default:
            this.src = './assets/redirection/45.svg';
            this.link = environment.link742;
            break;
        }
      }
      this.startTimer();
      this.openTrackingModal();
      return;
    }
    this.uiCommonService.showLoader('blocking');
    if (addon.purchased) {
      if (addon.subSkew.length) {
        this.authService.setSkewCode(addon.subSkew.join(','));
      } else {
        this.authService.setSkewCode(addon.productCode);
      }
      this.authService.setSkewName(addon.addOnName);
      if (this.menuService.getAvaiSkew().includes(addon.productCode + ':' + this.authService.getOutlet()) && addon.productCode === '744') {
        this.router.navigate(['/Integration/ecommerce/live-tracking-map/live']);
      } else if (this.menuService.getAvaiSkew().includes(addon.productCode + ':' + this.authService.getOutlet())) {
        if (this.faSkews.includes(this.authService.getSkewCode())) {
          localStorage.setItem('hideUnSyncOrders', 'false');
          await this.setMenuDynamically(addon.productCode)
        } else if (this.authService.getSkewCode() === '762') {
          this.router.navigate(['/Integration/' + this.menuService.getDefaultPage(this.authService.getSkewCode())]);
        } else if (this.authService.getSkewCode() === '764') {
          await this.setMenuDynamically(addon.productCode);
          // await this.checkIsConfigured()
        } else if (this.authService.getSkewCode() === '745') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '522') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '811') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '809') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '906') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '721') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '808') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '911') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '915') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '919') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '532') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '931') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '932') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '519') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '934') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '940') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '533') {
          if (addon.addonType.toLowerCase() === "loyalty") {
            await this.setMenuDynamically(addon.productCode);
          } else {
            this.router.navigate(['/Integration/ecommerce/under-construct']);
          }
        } else if (this.authService.getSkewCode() === '724') {
          if (addon.addonType.toLowerCase() === "loyalty") {
            await this.setMenuDynamically(addon.productCode);
          } else {
            this.router.navigate(['/Integration/ecommerce/under-construct']);
          }
        } else if (this.authService.getSkewCode() === '729') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '939') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '746') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '941') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '942') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '717') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '737') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '741') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '757') {
          await this.setMenuDynamically(addon.productCode);
        } else if (this.authService.getSkewCode() === '776') {
          await this.setMenuDynamically(addon.productCode);
        } else {
          this.menuService.removeParticularMenu('2');
          this.router.navigate(['/Integration/' + this.menuService.getDefaultPage(this.authService.getSkewCode())]);
        }
      } else {
        this.router.navigate(['/Integration/ecommerce/under-construct']);
      }
    } else {
      (Swal as any).fire({
        text: 'I want to experience ' + addon.displayName,
        type: 'question',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Know More'
      }).then(
        (result) => {
          if (result.value) {
            window.open('https://www.gofrugal.com/integration-pos', '_blank');
          }
        });
      this.uiCommonService.hideLoader();
    }
  }

  onGetCurrentPlanDetails() {
    let formObj = {
      "integrationAccountId": '' + this.authService.getServiceUserId(),
      "customerId": this.authService.getCustomerId(),
    }
    this.loginService.currentPlanDetails(formObj).subscribe(
      (data) => {
        if (data && data.plan) {
          this.currentPlanDetails = data;
        }
      },
      (err) => {
        this.notify.showError('Error', err);
      });
  }

  onRedirectToStore() {
    let idendity = '';
    if (this.currentPlanDetails.orderNo && this.currentPlanDetails.skewCode) {
      idendity = this.currentPlanDetails.orderNo + '742' + (this.currentPlanDetails.skewCode).replace('.', '');
    }
    let url = environment.storePurchase
      + 'idendity=' + idendity
      + '&eidendity=' + this.authService.encryptUsingMD5(idendity)
      + '&pfamily_code=742-01.0';
    window.open(url, '_blank');
  }

  async checkIsConfigured() {
    this.amazonConfigService.getConfiguredKeys().subscribe((res) => {
      if (res) {
        if (res['isConfigured']) {
          const menuId = '10003';
          this.router.navigate(['/Integration/' + this.menuService.getMenuLinkPassingId(menuId)]);
        } else {
          this.menuService.removeParticularMenu('2');
          this.router.navigate(['/Integration/' + this.menuService.getDefaultPage(this.authService.getSkewCode())]);
        }

      }
    });
  }

  async setMenuDynamically(skewCode) {
    const formObj = {
      'userId': this.authService.getServiceUserId(),
      'skewCode': skewCode,
      'roleId': this.authService.getRoleId()
    };
    this.loginService.getDynamicMenuFromHomePage(formObj).subscribe(
      (config) => {
        if (config) {
          config.forEach(res => { res['link'] = res['link'] === 'false' ? false : res.link; });
          this.menuListArray = config;
          if (this.menuListArray.length > 0) {
            let formMenu = [];
            this.menuListArray.forEach(res => {
              if (res.mappingStatus) {
                formMenu.push(res.id);
              }
              if (res.sub && res.sub.length > 0) {
                res.sub.forEach(res1 => {
                  if (res1.mappingStatus) {
                    formMenu.push(res1.id);
                  }
                });
              }
            });
            if (skewCode === "811" && config[0].link === "ecommerce/manual-sync") {
              config[0].link = "ondc/ondc-data-sync";
            }
            if (this.faSkews.includes(skewCode) && config[0].link === "ecommerce/manual-sync") {
              config[0].link = "fa/manual-sync";
            }
            this.menuService.setDynamicMenu(config);
            this.menuService.setDynamicMenuFromJSON(formMenu);

            for (let index = 0; index < formMenu.length; index++) {
              const element = formMenu[index];
              if (this.menuService.getMenuLinkPassingId(element) != false) {
                this.router.navigate(['/Integration/' + this.menuService.getMenuLinkPassingId(element)]);
                break;
              }
            }
          } else {
            this.uiCommonService.hideLoader()
            this.notify.showWarn('Warning', 'Menu is not mapped . Please contact admin to map menu')
          }
        }
      },
      (err) => {
      }
    );
  }

  openTrackingModal() {
    this.modalPopupClose = true;
  }

  onClickknowMore() {
    this.closeTrackingModal();
    window.open(this.link, '_self');
  }

  closeTrackingModal() {
    this.modalPopupClose = !this.modalPopupClose;
  }

  startTimer() {
    this.countTimer = 10;

    this.timeout = setInterval(() => {
      if (this.countTimer > 0) {
        this.countTimer -= 1;
      } else {
        this.onClickknowMore();
        clearInterval(this.timeout);
      }
      if (this.countTimer === 1) {
        this.sharedService.setConfetti(true);
      }
    }, 1000);
  }

  logout() {
		this.router.navigate(['/logout']);
	}
}
