import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CrmAPIService {

  constructor(
    private apiService: ApiService
  ) { }

  getTextAndJson(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.getTextAndJson(environment.loyaltyURL, path, param, header, showLoader)
      .pipe(catchError(this.handleError('Get - Alert')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  postCsv(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: FormData, showLoader?: Boolean): Observable<any> {
    return this.apiService.postUpCsv(environment.loyaltyURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Post - Alert')));
  }

  getEcomCRM(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.get(environment.loyaltyURL, path, param, header, showLoader)
      .pipe(catchError(this.handleError('Get - Alert')));
  }

  postEcomCRM(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.post(environment.loyaltyURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Post - Alert')));
  }
}
