import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-loader',
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.css']
})
export class CommonLoaderComponent implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input('loader') loaderType: string;

  constructor() { }

  ngOnInit() {
  }
}
