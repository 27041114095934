import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [MessageService]
})
export class NotificationComponent implements OnInit, OnDestroy {

  constructor(
    private notificationService: NotificationService,
    private messageService: MessageService
  ) { }

  subNotify: Subscription;
  subConf: Subscription;
  subExitMsg: Subscription;
  subProgress: Subscription;
  onConfirmCallback: () => void;
  onRejectCallback: () => void;
  onCloseCallback: () => void;

  ngOnInit() {
    this.subscribeToNotifications();
    this.subscribeToexitMessage();
    this.subscribeToConfirmMessage();
    this.subscribeToProgress();
  }

  subscribeToNotifications() {
    this.subNotify = this.notificationService.notificationChange
      .subscribe(notification => {

        // tslint:disable-next-line:max-line-length
        this.messageService.add({ key: 'notify', severity: notification['severity'], summary: notification['summary'], detail: notification['detail'], life: notification['life'] });
      });
  }

  subscribeToexitMessage() {
    this.subExitMsg = this.notificationService.exitMsgChange
      .subscribe(notification => {
        this.onCloseCallback = notification['onCloseCallback'];
        this.messageService.clear();
        // tslint:disable-next-line:max-line-length
        this.messageService.add({ key: 'close', sticky: true, life: 7000, severity: notification['severity'], summary: notification['summary'], detail: notification['detail'] });
      });
  }


  subscribeToProgress() {
    this.subProgress = this.notificationService.progressChange
      .subscribe(notification => {
        this.messageService.clear('progress');
        if (notification) {
          this.messageService.add({ key: 'progress', sticky: true, closable: false });
        }
      });
  }

  subscribeToConfirmMessage() {
    this.subConf = this.notificationService.confirmChange
      .subscribe(notification => {
        this.onConfirmCallback = notification['onConfirmCallback'];
        this.onRejectCallback = notification['onRejectCallback'];
        this.messageService.clear();
        // tslint:disable-next-line:max-line-length
        this.messageService.add({ key: 'confirm', sticky: true, life: 7000, severity: notification['severity'], summary: notification['summary'], detail: notification['detail'] });
      });
  }

  onConfirm() {
    this.onConfirmCallback();
    this.messageService.clear('confirm');
  }

  onReject() {
    this.onRejectCallback();
    this.messageService.clear('confirm');
  }

  close(): void {
    this.onCloseCallback();
    this.messageService.clear('close');
  }

  ngOnDestroy(): void {
    this.subConf.unsubscribe();
    this.subExitMsg.unsubscribe();
    this.subNotify.unsubscribe();
  }
}
