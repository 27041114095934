import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { SidemenuItemComponent } from './components/sidemenu-item/sidemenu-item.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { 
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG, 
    PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar-next';
// import { FlexLayoutModule } from '@angular/flex-layout';

import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InplaceModule } from 'primeng/inplace';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToolbarNotificationComponent } from './components/toolbar-notification/toolbar-notification.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ResponsiveTableComponent } from './components/tables/responsive-table/responsive-table.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CommonLoaderComponent } from './components/common-loader/common-loader.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ConfigInputComponent } from './components/config-input/config-input.component';
import { FormsModule } from '@angular/forms';
import { ChatBotComponent } from "./components/chat-bot/chat-bot.component";
import { NgxLoadersCssModule } from "ngx-loaders-css";
import { WhatsNewComponent } from './components/whats-new/whats-new.component';
import { OutletSelectionComponent } from './components/outlet-selection/outlet-selection.component';
import { AppCompanySelectionComponent } from './components/app-company-selection/app-company-selection.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { ConfettiCannonComponent } from './components/confetti-cannon/confetti-cannon.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { ServiceUserRequestComponent } from './components/service-user-request/service-user-request.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({

    declarations: [
        SidemenuComponent,
        SidemenuItemComponent,
        ToolbarNotificationComponent,
        ToolbarComponent,
        SearchBarComponent,
        FullscreenComponent,
        SidebarComponent,
        UserMenuComponent,
        ResponsiveTableComponent,
        NotificationComponent,
        ReportTableComponent,
        CarouselComponent,
        CommonLoaderComponent,
        ColorPickerComponent,
        ConfigInputComponent,
        ChatBotComponent,
        WhatsNewComponent,
        OutletSelectionComponent,
        AppCompanySelectionComponent,
        ConfettiCannonComponent,
        ServiceUserRequestComponent
    ],

    imports: [
        CommonModule,
        MatListModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatChipsModule,
        RouterModule,
        PerfectScrollbarModule,
        // FlexLayoutModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatSidenavModule,
        MatTabsModule,
        MatCardModule,
        MatSliderModule,
        MatProgressBarModule,
        MatSortModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        ToastModule,
        ButtonModule,
        TableModule,
        InputTextModule,
        InplaceModule,
        ProgressSpinnerModule,
        MatCheckboxModule,
        FormsModule,
        NgxLoadersCssModule,
        NgSelectModule
    ],


    exports: [
        SidemenuComponent,
        SidemenuItemComponent,
        ToolbarNotificationComponent,
        ToolbarComponent,
        SearchBarComponent,
        FullscreenComponent,
        SidebarComponent,
        UserMenuComponent,
        ResponsiveTableComponent,
        NotificationComponent,
        ReportTableComponent,
        CarouselComponent,
        CommonLoaderComponent,
        ColorPickerComponent,
        ConfigInputComponent,
        ChatBotComponent,
        ConfettiCannonComponent,
    ],

    providers: [
        DatePipe,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class SharedModule { }
