import { Injectable } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { UiCommonService } from '../../../app/shared/service/uiCommon.service';

@Injectable()
export class EcommerceInterceptor implements HttpInterceptor {
    constructor(
        private notify: NotificationService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private uiCommonService: UiCommonService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if ((request.url.includes(environment.ecomURL) || request.url.includes(environment.paymentURL) || request.url.includes(environment.ondcURL) 
            || (request.url.includes(environment.loyaltyURL)) || request.url.includes('micro_service/store/details')) && !(request.url.includes('payment/order') || request.url.includes('payment/authorize'))) {
                
            let token: string;
            if (request.url.includes(environment.ecomURL) || request.url.includes(environment.ondcURL)
            || request.url.includes(environment.loyaltyURL) || request.url.includes('micro_service/store/details') || request.url.includes(environment.paymentURL)){
               token  = this.authenticationService.getAuthToken();
            }
            
            if (token) {
                request = request.clone({ headers: request.headers.set('X-Auth-Token', token)});
            } else {
                this.authenticationService.logoutUser();
            }

            if (!request.headers.has('Content-Type')
                && !request.url.includes('/upload-image')
                && !request.url.includes('/save-personalization')
                && !request.url.includes('/throughCSV')
                && !request.url.includes('urbanpiper/save/all-items')
                && !request.url.includes('config/bulk')
                && !request.url.includes('/configure-shop-image')
                && !request.url.includes('/upload-cancelled-cheque')
                && !request.url.includes('/upload-item-back-image')
                && !request.url.includes('/bulk-update-item-config')
                && !request.url.includes('/upload/buyer-app-report')
                && !request.url.includes('/upload/update-settlement-recon-status')
                && !request.url.includes('/upload/seller-settlement-report')
                && !request.url.includes('/upload/logistics-settlement-report')){
                request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
            }

            if(this.authenticationService.getSkewCode() === '811' && request.url.includes('/bulk-update-item-config')){
                request = request.clone({headers: request.headers.set('Accept', 'application/json').set('timeout', '30000')});
            } else {
                request = request.clone({headers: request.headers.set('Accept', 'application/json')});
            }
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    this.uiCommonService.hideLoader();
                    sessionStorage.removeItem('currentUser');
                    this.router.navigate(['/logout']);
                    this.notify.showCloseMsg('info', 'Session Expired', 'Kindly login', () => {
                        this.router.navigate(['/login']);
                    });
                } else if (error.status === 406) {
                    if (error.error.message && error.error.message !== '') {
                        this.notify.showWarn('Error', error.error.message);
                    }
                } else if (error.status === 500) {
                    this.notify.showError('Sorry', 'Unexpected Error, Kindly contact GOFRUGAL Support.!');
                } else if (error.status === 502) {
                    this.notify.showError('Sorry', 'Unable to Reach GOFRUGAL Server, Kindly contact GOFRUGAL Support.!');
                }else if (error.status === 422) {
                    if (error.error.message && error.error.message !== '') {
                        this.notify.showWarn('Error', error.error.message);
                    }
                }
                else if (error.status === 503) {
                    this.notify.showError('Sorry', 'Unable to Reach GOFRUGAL Server, Kindly contact GOFRUGAL Support.!');
                } else if (error.status === 400) {
                    this.notify.showError('Sorry', 'Unexpected Error, Kindly contact GOFRUGAL Support.!');
                } else if (error.status === 0) {
                    this.notify.showError('Sorry', 'Unable to Reach GOFRUGAL Server, Kindly contact GOFRUGAL Support.!');
                } else if (error.status === 409) {
                    if(error.message.includes('/item-mapping/throughCSV?')){
                        this.notify.showError('Sorry', "Unexpected conflict response error");
                    }else{
                        this.notify.showError('Sorry', 'Unable to Reach GOFRUGAL Server, Kindly contact GOFRUGAL Support.!');   
                    }
                } else if (!error.status) {
                    this.notify.showError('Sorry', 'Unable to Reach GOFRUGAL Server, Kindly contact GOFRUGAL Support.!');
                } else {
                    this.uiCommonService.hideLoader();
                    sessionStorage.removeItem('currentUser');
                    this.router.navigate(['/logout']);
                    this.notify.showCloseMsg('info', 'Session Expired', 'Kindly login', () => {
                        this.router.navigate(['/login']);
                    });
                }
                return throwError(error);
            }));
    }
}