import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class SharedService {
  private recentOrders: Subject<any> = new Subject<any>();
  private pendingOrderCount: Subject<number> = new Subject<number>();
  private unsyncOrders: Subject<number> = new Subject<number>();
  private reConnected: Subject<boolean> = new Subject<boolean>();
  public liveCoordinates: Subject<any> = new Subject<any>();
  public getAllStatusCount: Subject<any> = new Subject<any>();
  private assureCare: Subject<boolean> = new Subject<boolean>();
  setOutletId: Subject<any> = new Subject<any>();
  private profileImageUrl: Subject<any> = new Subject<any>();
  private getProfileDetails: Subject<any> = new Subject<any>();
  private pendingNotificationCount: Subject<number> = new Subject<number>();
  private notificationOrderNo: Subject<number> = new Subject<number>();
  private selectedMenuId = new BehaviorSubject(null);
  shareSelectedMenuId = this.selectedMenuId.asObservable();
  private menuList = new BehaviorSubject(null);
  shareMenuList = this.menuList.asObservable();
  private companyCode: Subject<any> = new Subject<any>();
  announcementAvailabe: Subject<any> = new Subject<any>();
  feedbackAvailabe: Subject<any> = new Subject<any>();
  allRead: Subject<any> = new Subject<any>();
  confetti: Subject<any> = new Subject<any>();
  private recentTickets: Subject<any> = new Subject<any>();
  private bulkOrderUpdateStatus = new Subject<any>();
  constructor() {}

  setAllStatusCount(count: any) {
    this.getAllStatusCount.next(count);
  }

  getStatusCount(): Observable<any> {
    return this.getAllStatusCount;
  }

  setAssureCare(assure: boolean) {
    this.assureCare.next(assure);
  }

  getAssureCare(): Observable<boolean> {
    return this.assureCare;
  }

  setRecentOrders(orders: any) {
    this.recentOrders.next(JSON.stringify(orders));
  }

  getRecentOrders(): Observable<any> {
    return this.recentOrders;
  }

  setPendingOrderCount(count: number) {
    this.pendingOrderCount.next(count);
  }

  getPendingOrderCount(): Observable<number> {
    return this.pendingOrderCount;
  }

  setUnsyncOrders(count: number) {
    this.unsyncOrders.next(count);
  }

  getUnsyncOrders(): Observable<number> {
    return this.unsyncOrders;
  }

  setReconnected(isconnected: boolean) {
    this.reConnected.next(isconnected);
  }

  getReconnected(): Observable<boolean> {
    return this.reConnected;
  }

  setLiveCoordinates(coordinates: Object) {
    this.liveCoordinates.next(coordinates);
  }

  setOutletIdVal(data) {
    this.setOutletId.next(data);
    // this.setOutletId = data
  }

  getOutletId(): Observable<any> {
    return this.setOutletId;
  }

  setProfilePicUrl(url: string) {
    this.profileImageUrl.next(url);
  }

  getProfilePicUrl(): Observable<any> {
    return this.profileImageUrl;
  }

  setViewProfile(flag: any) {
    this.getProfileDetails.next(flag);
  }

  getViewProfile(): Observable<any> {
    return this.getProfileDetails;
  }

  setPendingNotificationCount(count: number) {
    this.pendingNotificationCount.next(count);
  }

  getPendingNotificationCount(): Observable<number> {
    return this.pendingNotificationCount;
  }

  setNotificationOrderNo(orderNo: any) {
    this.notificationOrderNo.next(orderNo);
  }

  getNotificationOrderNo(): Observable<number> {
    return this.notificationOrderNo;
  }

  setCompanyCodeChange(company: any) {
    this.companyCode.next(company);
  }

  getCompanyCodeChange(): Observable<any> {
    return this.companyCode;
  }

  setParentMenuId(menuId: string) {
    this.selectedMenuId.next(menuId);
  }

  setMenuList(menus: any) {
    this.menuList.next(menus);
  }

  getAnnouncementAvailable(): Observable<any> {
    return this.announcementAvailabe;
  }

  setAnnouncementAvailable(isAnnouncementAvailable: any) {
    this.announcementAvailabe.next(isAnnouncementAvailable);
  }

  getFeedbackAvailable(): Observable<any> {
    return this.feedbackAvailabe;
  }

  setFeedbackAvailable(isFeedbackAvailable: any) {
    this.feedbackAvailabe.next(isFeedbackAvailable);
  }

  setAllReadValue(allRead: any) {
    this.allRead.next(allRead);
  }

  getAllReadValue(): Observable<any> {
    return this.allRead;
  }

  setConfetti(confetti) {
    this.confetti.next(confetti);
  }

  getConfetti (): Observable<any> {
    return this.confetti;
  }

  setRecentTicket(ticket: any) {
    this.recentTickets.next(JSON.stringify(ticket));
  }

  getRecentTicker(): Observable<any>{
    return this.recentTickets;
  }

  setBulkUpdateStatus(bulkUpdatedStatus: any){
    this.bulkOrderUpdateStatus.next(JSON.stringify(bulkUpdatedStatus))
  }

  getBulkUpdateStatus(): Observable<any>{
    return this.bulkOrderUpdateStatus;
  }

}
