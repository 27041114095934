import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentApiService {

  constructor(
    private apiService: ApiService
  ) { }

  get(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.get(environment.paymentURL, path, param, header, showLoader)
      .pipe(catchError(this.handleError('Get - ECommerce')));
  }

  // tslint:disable-next-line: max-line-length
  put(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.put(environment.paymentURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Put - ECommerce')));
  }

  // tslint:disable-next-line: max-line-length
  post(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.post(environment.paymentURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Post - ECommerce')));
  }

  // tslint:disable-next-line: max-line-length
  delete(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.delete(environment.paymentURL, path, param, header, showLoader)
      .pipe(catchError(this.handleError('Delete - ECommerce')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
}
