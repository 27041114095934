import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../core/services/authentication.service';
import {Router} from '@angular/router';
import {StompService} from '../../core/web-socket/StompService';
import {LiveTrackingStompService} from '../../core/web-socket/LiveTrackingStompService';
import {CookieService} from 'ngx-cookie-service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  url: any;
  flag: any;
  domainUrl: any;

  constructor(
      private authenticationService: AuthenticationService,
      private router: Router,
      private stompService: StompService,
      private liveTrackingStompService: LiveTrackingStompService,
      private cookieService: CookieService,
      private sanitizer: DomSanitizer,
  ) {
  }

  async ngOnInit() {
    this.stompService.disconnect();
    this.liveTrackingStompService.disconnect();
    this.cookieService.deleteAll('/', '/');
    if (this.flag === 1) {
      (async () => {
        this.url = this.getSafeUrl(this.domainUrl + '/TruePOS/logout.do');

        await this.delay(5000);

        this.router.navigate(['\login']);
      })();
    } else if (this.authenticationService.getSkewCode() === '8111'){
      this.router.navigate(['\admin']);
    } else {
      this.router.navigate(['\login']);
    }
    this.authenticationService.logoutUser();
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  login() {
    this.router.navigate(['\login']);
  }
}
