import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationChange: Subject<Object> = new Subject<Object>();
  confirmChange: Subject<Object> = new Subject<Object>();
  exitMsgChange: Subject<Object> = new Subject<Object>();
  progressChange: Subject<Object> = new Subject<Object>();

  showSuccess(summary: string, detail: string) {
    this.notificationChange.next({ severity: 'success', summary: summary, detail: detail, life: 3000 });
  }

  showInfo(summary: string, detail: string) {
    this.notificationChange.next({ severity: 'info', summary: summary, detail: detail, life: 3000 });
  }

  showWarn(summary: string, detail: string) {
    this.notificationChange.next({ severity: 'warn', summary: summary, detail: detail, life: 5000 });
  }

  showError(summary: string, detail: string) {
    this.notificationChange.next({ severity: 'error', summary: summary, detail: detail, life: 3000 });
  }

  showConfirm(severity: string, summary: string, detail: string, onConfirmCallback: () => void, onRejectCallback: () => void) {
    // tslint:disable-next-line: max-line-length
    this.confirmChange.next({ severity: severity, summary: summary, detail: detail, onConfirmCallback: onConfirmCallback, onRejectCallback: onRejectCallback });
  }

  showCloseMsg(severity: string, summary: string, detail: string, onCloseCallback: () => void) {
    this.exitMsgChange.next({ severity, summary, detail, onCloseCallback });
  }

  changeProgress(value: boolean) {
    this.progressChange.next(value);
  }
}
