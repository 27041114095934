import { Injectable } from '@angular/core';
import { EcommerceAPIService } from '../../../core/http/ecommerce/ecommerce-api.service';
import { Observable } from 'rxjs';
import { CategoryHeader, ItemToggle, OutletDetail } from '../../../core/models/integration/ecommerce';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { AccountApiService } from '../../../core/http/account/account-api.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class EcommerceService {

  constructor(
    private ecommerceService: EcommerceAPIService,
    private authenticationService: AuthenticationService,
    private accountService: AccountApiService,
    private cookieService: CookieService
  ) {
  }

  public getOutletDetails(): Observable<OutletDetail[]> {
    const param = new HttpParams().set('loginId', this.authenticationService.getEmailId());
    const header = new HttpHeaders().set('skew', this.authenticationService.getSkewCode()).set('userId', ''+this.authenticationService.getServiceUserId());
    return this.accountService.get('micro_service/store/details', param, header);
  }

  public getShopDetails(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('get/configured-store', param, null, true);
  }

  public updateAssignOutlet(outletId: any, selectedOrderId: any, selectedOrderRefId: any): Observable<any> {
    const header = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId)
      .set('orderId', selectedOrderId)
      .set('onlineVendorId', selectedOrderRefId);
    return this.ecommerceService.put('assign-outlet', param, header, null, true);
  }

  public getCategoryForOrderEasy(outletSelected: string, filter: string, pageIndex: any, pageSize: any): Observable<CategoryHeader> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletSelected)
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('filter', filter);
    return this.ecommerceService.get('get/category-master', param);
  }

  public getCategoryForBannersOrderEasy(outletSelected: string, filter: string, pageIndex: any, pageSize: any): Observable<CategoryHeader> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletSelected)
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('filter', filter);
    return this.ecommerceService.get('get/category-master', param, null, true);
  }

  public saveImageAsBase64(itemId: string, formData: FormData, type: any): Observable<any> {
    const header = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('id', itemId)
      .set('type', type);
    return this.ecommerceService.postFormData('upload-images', param, header, formData, true);
  }

  public deleteItemImage(body: any, type: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('type', type)
    return this.ecommerceService.post('delete-image', param, header, body, true);
  }

  public getOrders(searchTag: string, status: string, pageIndex: number,
    pageSize: number, showLoader: Boolean = false, outletId?: any, orderSource?: string, orderExpressSource?: string,
    startFilterDate?: string, endFilterDate?: string,
    filterSlotDate?, filterSlotId?): Observable<any> {
    if (startFilterDate === undefined || endFilterDate === undefined) {
      startFilterDate = '';
      endFilterDate = '';
      filterSlotDate = '';
      filterSlotId = '';
    }
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId ? outletId : this.authenticationService.getOutlet())
      .set('searchTag', searchTag)
      .set('status', status)
      .set('source', orderSource ? orderSource : 'both')
      .set('startFilterDate', startFilterDate)
      .set('endFilterDate', endFilterDate)
      .set('deliverySlotDate', filterSlotDate)
      .set('deliverySlotId', filterSlotId)
      .set('deliveryType', orderExpressSource ? orderExpressSource : 'both')
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());
    return this.ecommerceService.get('get/order', param, null, showLoader);
  }

  public getUnAssignedOrdersList(searchTag: string, status: string, pageIndex: number,
    pageSize: number, showLoader: Boolean = false, outletId?: any,
    startFilterDate?: string, endFilterDate?: string): Observable<any> {
    if (startFilterDate === undefined || endFilterDate === undefined) {
      startFilterDate = '';
      endFilterDate = '';
    }
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('searchTag', searchTag)
      .set('status', status)
      .set('startFilterDate', startFilterDate)
      .set('endFilterDate', endFilterDate)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());
    return this.ecommerceService.get('get/orders', param, null, showLoader);
  }


  public getOrderDetails(orderId: string, onlineReferenceNo: string, outletId?: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId ? outletId : this.authenticationService.getOutlet())
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('orderId', orderId);
    return this.ecommerceService.get('get/order/id', param, null, true);
  }

  public getUnAssignedOrderDetails(orderId: string, onlineReferenceNo: string, outletId?: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('orderId', orderId);
    return this.ecommerceService.get('get/unassigned-order/id', param, null, true);
  }

  public updateOrderStatus(orderId: string, onlineReferenceNo: string, toStatus: string, body: any, message: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('orderId', orderId)
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('status', toStatus)
      .set('message', message);
    return this.ecommerceService.post('update/order/status', param, header, body, true);
  }

  public updateAmazonOrderStatus(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('update/amazon-order-status', param, header, body, true);
  }

  public syncDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('ids', body);
    return this.ecommerceService.get('get/sync-status', param, header, true);
  }

  public pushOrderPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/push/order/pos', param, headers, false);
  }

  public pullSalesOrderStatusFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/do/salesorder-sync', param, headers, false);
  }

  public pullChargeMasterFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/do/charge-sync', param, headers, false);
  }


  public pushSalesOrderStatusOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/push/salesorder-online', param, headers, false);
  }

  public pushSalesOrderToPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/push/order/pos', param, headers, false);
  }

  public pullSalesOrderFromOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/pull/salesorder-online', param, headers, false);
  }

  public pushSingleOrderPOS(onlineReferenceNo: string, onlineVendorSoNo: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('onlineVendorSoNo', onlineVendorSoNo);
    return this.ecommerceService.get('/push/order/pos', param, headers, false);
  }

  public pushItemsOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('push/item-online', param, headers, false);
  }

  public pullItemsPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/item-sync', param, headers, false);
  }

  // tempFunctions

  public categorySyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/category-sync', param, headers, false);
  }

  public pushcategorySyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('push/category-online', param, headers, false);
  }

  public CustomerSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/customer-sync', param, headers, false);
  }

  public pullSalesReturnFromOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('pull/sale-return-online', param, headers, false);
  }

  public pushSalesReturnToPos(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('push/sale-return-to-pos', param, headers, false);
  }

  public pullPendingSoQuantityFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/pending-so-qty-sync', param, headers, false);
  }

  public pullPaymentTypesFromPos(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('sync/payment-types', param, headers, false);
  }

  public getService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/salesorder-sync', param, headers, false);
  }

  public taxSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/tax-sync', param, headers, false);
  }

  public pushtaxSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('push/tax-online', param, headers, false);
  }

  // tempFunctions

  public purchaseOrderSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletSelected);
    return this.ecommerceService.get('do/po-sync', param, headers, false);
  }

  public receiptNoteSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletSelected);
    return this.ecommerceService.get('do/sales-sync', param, headers, false);
  }

  public salesSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletSelected);
    return this.ecommerceService.get('do/sales-sync', param, headers, false);
  }

  public pullOrderStatusPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/order-sync', param, headers, false);
  }

  public pullModifierGroupMaster(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/modifier-group-sync', param, headers, false);
  }

  public pullModifierMaster(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/modifier-sync', param, headers, false);
  }

  public getTrans(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', this.authenticationService.getOutlet())
      .set('filter', 'status=pending');
    return this.ecommerceService.get('get/item-update/list', param);
  }

  public getTransDetail(transId: string): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('transId', transId)
      .set('outletId', this.authenticationService.getOutlet());
    return this.ecommerceService.get('get/item-update/id', param);
  }

  public updateTransStatus(transId: string, status: string): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('transId', transId)
      .set('status', status)
      .set('approvedBy', this.authenticationService.getUserName())
      .set('outletId', this.authenticationService.getOutlet());
    return this.ecommerceService.get('approve/item-update', param);
  }

  public SupplierSyncFromPOS(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('pull/supplier-from-pos', param, headers, false);
  }

  public supplierSyncOnline(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('push/supplier-online', param, headers, false);
  }

  public getItemReport(outletSelected: string, pageIndex: string, pageSize: any, categoryList: any, filter: string): Observable<ItemToggle[]> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletSelected)
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('orFilter', categoryList)
      .set('filter', filter);
    return this.ecommerceService.get('get/item-master/report', param, null, true);
  }

  public getAmazonDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('get/amazon-order-details', param, header, body, true);
  }

  public saveItemImageAsBase64WithOutletId(itemId: string, formData: FormData, type: any, outletId: any): Observable<any> {
    const header = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('id', itemId)
      .set('outletId', outletId)
      .set('type', type);
    return this.ecommerceService.postFormData('upload-images', param, header, formData, true);
  }

  public getAmazonOrders(pageIndex: number, pageSize: number, showLoader: Boolean = false, outletId: any, filter: any, orFilter: any, sort: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId ? outletId : this.authenticationService.getOutlet())
      .set('filter', filter)
      .set('orFilter', orFilter)
      .set('sort', sort)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.ecommerceService.get('get/sales-orders', param, null, showLoader);
  }

  // Trigger OTP for Data flush
  triggerOTPForFlush(formTriggerData) {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/trigger-otp', param, header, formTriggerData, true);
  }

  // Flush data for Data flush
  flushDataMasters(flushData) {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
      return this.ecommerceService.post('v1/flush-data', param, header, flushData, true);
  }

  // Get trouble shooting details
  getTroubleShootingDetails(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
    return this.ecommerceService.post('v1/data-sync/troubleshoot', param, header, formObj, true);
  }

  // Get amazon details for security
  public getSecurityAmazonDetails(body: any, type): Observable<any> {
    // const header = new HttpHeaders()
    // .set('responseType', 'arraybuffer' as 'json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('type', type);
    return this.ecommerceService.postPDF('get/amazon-order-file', param, null, body, true);
  }

  // Get retrieve pick up slots
  public getRetriveSlots(data: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('amazon/retrieve-pickup-slots', param, header, data, true);
  }

  // Post retrieve pick up slots
  postPickUpSlots(body) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('update/amazon/pickup-time-slot', param, header, body, true);
  }

  // UnSync Orders Loading
  public unSyncOrderPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
      return this.ecommerceService.get('/push/order/pos', param, headers, true);
  }

  // Get recommended package details for amazon
  public getRecommendedPackageDetails(formObj: any, outletId?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId ? outletId : this.authenticationService.getOutlet());
    return this.ecommerceService.post('amazon/retrieve-default-packageDetails', param, header, formObj, true);
  }

  // Get Item mapping fro Unicomerce api
  public getItemMapping(outletId): Observable<any> {
    // pageSize=1000000&filter=appliesOnline=1
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId)
      .set('pageSize', '' + 1000000)
      .set('filter', 'appliesOnline=1');
    return this.ecommerceService.get('get/unicommerce-item-export', param, null, true);
  }

  // Update item mapping for unicommerce
  updateItemMapping(formObj) {
    const header = new HttpHeaders({ timeout: `${30000}` }).set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('update/unicommerce-item-mapping', param, header, formObj, true);
  }

  pullOfferDetailsFromPOS(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/do/offer-sync', param, header, null, true);
  }

  pullItemImageFromGoAct(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId())).set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/do/item-image-sync', param, header, null, true);
  }

   uploadItemMappingCsv(data: FormData): Observable<any>  {
    const header = new HttpHeaders({ timeout: `${30000}` }).set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'))
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.postMultipartFile('item-mapping/throughCSV', param, header, data, true);
  }


   downloadItemMappingCsv(): Observable<any> {
    const header = new HttpHeaders({ timeout: `${300000}` })
    .set('Accept', 'text/csv');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.getText('item-mapping/downloadCSV', param, header, true);
  }

  public resetSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('reset-sync', param, headers, false);
  }

  getAllMastersForFlush( selectedOutletID: any) {
    const headers = new HttpHeaders({ timeout: `${20000}` }).set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
        const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
        return this.ecommerceService.post('v1/get-masters', param, headers, null, true);
    }

    // Get Order status for order processing screen 
    public getOrderStatusforOrderProcessingScreen(outletId?, vendor?): Observable<any> {
      const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId);
      return this.ecommerceService.get('get/orderStatus', param, null, true);
    }

     // Get Add On List in Order Processing Screen
  public getAddOnListInOrderProcessingScreen(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('hasSalesScreen', '' + true);
    return this.ecommerceService.get('get/addons', param, null, true);
  }

  // Get content master for admin 
  public getContentMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/get/content-masters', param, header, body, true);
  }

   // Save user onfiguration on SMS / WNS / Mobile notification
   public saveUserConfigurationForNotification(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/user-content', param, header, body, true);
  }

  public getBflSellerConfig() {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('get/bfl-marketplace/seller-config', param, header, true);
  }

  public saveBflSellerConfig(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('save/bfl-marketplace/seller-config', param, header, body, true);
  }

  public getBflDashboard(outletId: number) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('vendor', this.authenticationService.getSkewName())
    .set('outletId', outletId);
    return this.ecommerceService.get('get/bfl-marketplace/product-dashboard', param, header, true);
  }

  public getBflProductConfig(outletId: number) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('vendor', this.authenticationService.getSkewName())
    .set('outletId', outletId);
    return this.ecommerceService.get('get/bfl-marketplace/product-config', param, header, true);
  }

  public saveBflProductConfig(body, outletId): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('vendor', this.authenticationService.getSkewName())
    .set('outletId', outletId);
    return this.ecommerceService.post('save/bfl-marketplace/product-config', param, header, body, true);
  }

  public pullArticlesFromBflPortal(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('pull/inventory-online', param, headers, false);
  }

  public sentServiceUserRequsetOtp() {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authenticationService.getAuthToken())
    .set('skew', this.authenticationService.getSkewCode())
    .set('userId', ''+this.authenticationService.getServiceUserId());
    const body = {
      'userId': Number(this.authenticationService.getServiceUserId()),
      'email_id': this.authenticationService.getEmailId()
    }
    return this.accountService.post('sul/generate-otp', null, header, body, false);
  }

  public verifyServiceUserRequsetOtp(body: Object) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authenticationService.getAuthToken())
    .set('skew', this.authenticationService.getSkewCode())
    .set('userId', ''+this.authenticationService.getServiceUserId());
    return this.accountService.post('sul/customer-consent', null, header, body, false);
  }

  public getCsrfToken(): Observable<any[]> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.ecommerceService.get('generate/csrf', new HttpParams(), header);
  }
}
