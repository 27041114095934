import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FoodAggregatorService {

  constructor( 
    private apiService: ApiService
  ) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
  
  get(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.get(environment.faURL, path, param, header, showLoader)
    .pipe(catchError(this.handleError('Get - Food Aggregator')));
  }

  post(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.post(environment.faURL, path, param, header, body, showLoader)
    .pipe(catchError(this.handleError('Post - Food Aggregator')));
  }

  postUpCsv(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: FormData, showLoader?: Boolean): Observable<any> {
    return this.apiService.postUpCsv(environment.faURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Post - Food Aggregator')));
  }
}
