import { Injectable } from '@angular/core';
import { OutletDetail } from '../../../core/models';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PaymentApiService } from '../../../core/http/payment/payment-api.service';
import { AccountApiService } from '../../../core/http/account/account-api.service';
import { EcommerceAPIService } from '../../../core/http/ecommerce/ecommerce-api.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private paymentApiService: PaymentApiService,
    private accountService: AccountApiService,
    private authenticationService: AuthenticationService,
    private ecommerceAPIService: EcommerceAPIService,
    private cookieService: CookieService
  ) { }

  public getOutletDetails(): Observable<OutletDetail[]> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken()).set('skew', this.authenticationService.getSkewCode()).set('userId', ''+this.authenticationService.getServiceUserId());
    const param = new HttpParams()
        .set('loginId', encodeURIComponent(this.authenticationService.getEmailId()))
        .set('skipHq', '1');
    return this.accountService.get('micro_service/store/details', param, header);
  }

  public paypaySaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));       
    return this.paymentApiService.post('configure-paypay', param, headers, body, true);
  }

  public paypayGetConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/paypay-configuration', param);
  }

  public paytmEdcSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));       
    return this.paymentApiService.post('configure-paytm-edc', param, headers, body, true);
  }

  public paytmEdcGetConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/paytm-edc-configuration', param);
  }

  public worldlineSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie')); 
    return this.paymentApiService.post('configure-worldline', param, headers, body, true);
  }

  public worldlineGetConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/worldline-configuration', param);
  }
  
  public ezetapSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));    
    return this.paymentApiService.post('configure-ezetap', param, headers, body, true);
  }

  public ezetapGetConfig(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/ezetap-configuration', param);
  }

  public mpesaSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));    
    return this.paymentApiService.post('configure-mpesa', param, headers, body, true);
  }

  public mpesaGetConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/mpesa-configuration', param);
  }

  public mpesaupdateConfig(outletId: string, tillId: string, status: string, body: any) {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('outletId', outletId)
      .set('tillId', tillId)
      .set('status', status);
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));   
    return this.paymentApiService.post('mpesa-config/update-status', param, headers, body);
  }

  public paytmSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.post('configure-paytm', param, new HttpHeaders(), body, true);
  }

  public paytmGetConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/paytm-configuration', param);
  }

  public paytmUpdateConfig(outletId: string, tillId: any, status: string, body: any) {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('outletId', outletId)
      .set('tillId', tillId)
      .set('status', status);
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));    
    return this.paymentApiService.post('paytm-config/update-status', param, headers, body);
  }

  public paytmGetTransDetails(pageSize: any, pageIndex: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', '' + this.authenticationService.getSkewName())
        .set('size', pageSize)
        .set('page', pageIndex);
    return this.paymentApiService.get('get/paytm-trans-dtl', param);
  }

  public payuSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));      
    return this.paymentApiService.post('configure-payu', param, headers, body, true);
  }

  public payuGetConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/payu-configuration', param);
  }

  public saveImage(id: any, formData: FormData, type: any): Observable<any> {
    const header = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('id', id)
      .set('type', type)
    return this.ecommerceAPIService.postFormData('upload-images', param, header, formData, true);
  }

  public pinelabsSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));     
    return this.paymentApiService.post('configure-pinelabs', param, headers, body, true);
  }

  public pinelabsGetConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/pinelabs-configuration', param);
  }

  public wizaSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));
    return this.paymentApiService.post('configure-wiza', param, header, body, true);
  }

  public wizaGetConfig(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');

    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/wiza-configuration', param, header);
  }

  public payfactoGetConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/payfacto-configuration', param);
  }

  public payfactoSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));     
    return this.paymentApiService.post('configure/payfacto', param, headers, body, true);
  }

  public linklyGatewaySaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));
    return this.paymentApiService.post('configure/linkly', param, header, body, true);
  }

  public linklyGatewayGetConfig(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/linkly-configuration', param, header);
  }

  public pesapalSaveConfig(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', 'b7b6c548-45f3-433e-bd0c-bc3cce131167');
    return this.paymentApiService.post('configure-pesapal', new HttpParams(), header, body, true);
  }

  public pesapalGetConfig(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', 'b7b6c548-45f3-433e-bd0c-bc3cce131167');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/pesapal-configuration', param, header);
  }

  public linklyEdcSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));
    return this.paymentApiService.post('configure/linkly-device', param, header, body, true);
  }

  public linklyEdcGetConfig(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/linkly-device-configuration', param, header);
  }
  
  public phonepeSaveConfig(body: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie'));
    return this.paymentApiService.post('configure/phonepe', param, header, body, true);
  }

  public phonepeGetConfig(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/phonepe-configuration', param, header);
  }

  public stripeSaveConfig(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.paymentApiService.post('configure/stripe', new HttpParams(), header, body, true);
  }

  public stripeGetConfig(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
    return this.paymentApiService.get('get/stripe-configuration', param, header);
  }
  
  public posVersionCompatibility(body: any): Observable<any> {
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
    const headers = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfPGParam='+this.cookieService.get('csrfPGCookie')); 
    return this.paymentApiService.post('pos/version/compatibility', param, headers, body, true);
  }

  public getCsrfToken(): Observable<any[]> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.paymentApiService.get('generate/csrf', new HttpParams(), header);
  }
}
