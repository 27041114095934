import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  constructor() { }

  @Input() addons;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  number = [];
  random: any;
  ngOnInit() {
    this.number = Array(Math.ceil(this.addons.length / 5)).fill(0).map((_x, i) => i);
    this.random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
  }

  onclick(addon) {
    this.onClick.emit(addon);
  }

}
