import { Component, OnInit } from '@angular/core';
import {SharedService} from '../../service/shared.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-confetti-cannon',
  templateUrl: './confetti-cannon.component.html',
  styleUrls: ['./confetti-cannon.component.scss']
})
export class ConfettiCannonComponent implements OnInit {
  private subscription: Subscription;

  constructor(
      private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.subscription = this.sharedService.getConfetti().subscribe(
        res => {
          if (res) {
            this.shoot();
            this.sharedService.setConfetti(false);
        }
    });
  }

  shoot() {
    let particleCount = 200;
    let angle = -135;
    let spread = 150;
    try {
      this.confetti({
        angle: angle,
        spread: spread,
        particleCount: particleCount,
        origin: {
          x: 1,
          y: -0.2
        }
      });
    } catch (e) {
      // noop, confettijs may not be loaded yet
    }
    particleCount = 200;
    angle = -45;
    spread = 150;
    try {
      this.confetti({
        angle: angle,
        spread: spread,
        particleCount: particleCount,
        origin: {
          x: 0,
          y: -0.2
        }
      });
    } catch (e) {
      // noop, confettijs may not be loaded yet
    }
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  confetti(args: any) {
    return window['confetti'].apply(this, arguments);
  }
}
