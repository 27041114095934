import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './core/gaurds/auth.guard';
import { LogoutComponent } from './modules/logout/logout.component';
import { HomePageComponent } from './modules/home-page/home-page.component';
import { PdfViewerComponent } from './modules/pdf-viewer/pdf-viewer.component';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'cpp', loadChildren: () => import('./modules/cpp/cpp.module').then(m => m.CppModule) },
  { path: 'register', loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule) },
  { path: 'Integration', loadChildren: () => import('./modules/integration/integration.module').then(m => m.IntegrationModule) },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)},
  // {path: 'DebugPage', loadChildren: './auth/auth.module#AuthModule'},
  { path: 'logout', component: LogoutComponent },
  { path: 'HomePage', component: HomePageComponent, canActivate: [AuthGuard] },
  { path: 'pdf-viewer/:q', component: PdfViewerComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'login' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
