<p-toast key='notify' [baseZIndex]="5000"></p-toast>

<p-toast position="center" styleClass="custom-toast" key='close' (onClose)="close()" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="close-message">
    <div style="text-align: center" class="pt-3">
      <h5>{{message.summary}}</h5>
      <p>{{message.detail}}</p>
    </div>
    <div class="ui-g ui-fluid" style="padding-left: 35%; padding-right: 35%;">
      <button type="button" pButton (click)="close()" label="Ok" class="ui-button-primary"></button>
    </div>
  </ng-template>
</p-toast>

<p-toast position="center" key="confirm" (onClose)="onReject()" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="confirm-message">
    <div style="text-align: center">
      <i class="pi pi-info-circle" style="font-size: 3em"></i>
      <h3>{{message.summary}}</h3>
      <p>{{message.detail}}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-6">
        <button type="button" pButton (click)="onConfirm()" label="Yes" class="ui-button-success"></button>
      </div>
      <div class="ui-g-6">
        <button type="button" pButton (click)="onReject()" label="No" class="ui-button-secondary"></button>
      </div>
    </div>
  </ng-template>
</p-toast>


<p-toast position="center" styleClass="remove-shadow" key='progress' [modal]="true" >
  <ng-template pTemplate="progress-temp">
    <div style="text-align: center">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </ng-template>
</p-toast>