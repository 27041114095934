import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { FoodAggregatorService } from '../../../core/http/food-aggregator/food-aggregator.service';
import { ActivePlatform, CategoryHeader, ItemToggle, OutletDetail, Product, StoreConfig, StoreEdit, UrbanpiperConfig } from '../../../core/models';
import { EcommerceAPIService } from '../../../core/http/ecommerce/ecommerce-api.service';
import { ApiService } from '../../../core/http/api.service';
import { environment } from '../../../../environments/environment';
import { FaConfigData } from './fa-data';
import { AccountApiService } from '../../../core/http/account/account-api.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FAService {

  configData = FaConfigData;

  constructor(
    private authService: AuthenticationService,
    private foodAggregatorService: FoodAggregatorService,
    private ecommerceAPIService: EcommerceAPIService,
    private apiService: ApiService,
    private accountService: AccountApiService,
    private cookieService: CookieService
  ) { }

  // Banner services

  public getConfigDeliveryDays(outletId: any): Observable<any> {
    let body = {};
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + outletId)
      .set('vendor', this.authService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.foodAggregatorService.post('get/v1/delivery-days', param, header, body, true);
  }

  public getOutletDetails(): Observable<OutletDetail[]> {
    const vendor = this.authService.getSkewName();
    const header = new HttpHeaders().set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + 0)
      .set('orderNo', '' + '0')
      .set('vendor', vendor);
    return this.foodAggregatorService.get('get/configured-store', param, header, true);
  }

  // Get delivery days 
  public getDeliveryDays(outletId?: number, selectedDay?: string): Observable<any> {
    let formBody = {
      'userId': this.authService.getServiceUserId(),
      'vendor': this.authService.getSkewName(),
      'outletId': outletId
    }
    if (selectedDay) {
      formBody['day'] = selectedDay
    }
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.foodAggregatorService.post('get/v1/delivery-slot-mapping', param, header, formBody, true);
  }

  public orderReschedule(outletId: any, body: any): Observable<any> {
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + outletId)
      .set('vendor', this.authService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.foodAggregatorService.post('v1/order-reschedule', param, header, body, true);
  }

  // Urbane piper config services

  private header() {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    return header;
  }

   // Get Add On List in Order Processing Screen
   public getAddOnListInOrderProcessingScreen(): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('hasSalesScreen', '' + true);
    return this.foodAggregatorService.get('get/addons', param, header, true);
  }

  checkOrderSync(outletId):Observable<any>{
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', outletId);
    return this.foodAggregatorService.post('urbanpiper/check-order-sync', param, header, null, true);
  }

   // Get Order status for order processing screen 
   public getOrderStatusforOrderProcessingScreen(outletId?, vendor?): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId);
    return this.foodAggregatorService.get('get/orderStatus', param, header, true);
  }

  public getOrders(searchTag: string, status: string, pageIndex: number,
    pageSize: number, showLoader: Boolean = false, outletId?: any, orderSource?: string, orderExpressSource?: string,
    startFilterDate?: string, endFilterDate?: string,
    filterSlotDate?, filterSlotId?): Observable<any> {
    if (startFilterDate === undefined || endFilterDate === undefined) {
      startFilterDate = '';
      endFilterDate = '';
      filterSlotDate = '';
      filterSlotId = '';
    }
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId ? outletId : this.authService.getOutlet())
      .set('searchTag', searchTag)
      .set('status', status)
      .set('source', orderSource ? orderSource : 'both')
      .set('startFilterDate', startFilterDate)
      .set('endFilterDate', endFilterDate)
      .set('deliverySlotDate', filterSlotDate)
      .set('deliverySlotId', filterSlotId)
      .set('deliveryType', orderExpressSource ? orderExpressSource : 'both')
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());
    return this.foodAggregatorService.get('get/order', param, header, showLoader);
  }

  public getOrderDetails(orderId: string, onlineReferenceNo: string, outletId?: any): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId ? outletId : this.authService.getOutlet())
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('orderId', orderId);
    return this.foodAggregatorService.get('get/order/id', param, header, true);
  }

  public updateOrderStatus(orderId: string, onlineReferenceNo: string, toStatus: string, body: any, message: string, channel: string): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('orderId', orderId)
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('status', toStatus)
      .set('message', message);
      if ((channel === "swiggy" || channel === "zomato") && (this.authService.getSkewCode() === '926')) {
        return this.foodAggregatorService.post('direct/'+channel+'/update/order/status', param, header, body, true);
      } else {
        return this.foodAggregatorService.post('urbanpiper/update/order/status', param, header, body, true);
      }
  }

  public pushSingleOrderPOS(onlineReferenceNo: string, onlineVendorSoNo: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` }).set('X-Auth-Token', this.authService.getAuthToken());;
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('onlineVendorSoNo', onlineVendorSoNo);
    return this.foodAggregatorService.get('push/order/pos', param, headers, false);
  }

  public getRiderInfo(orderId: string, onlineReferenceNo: string): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('vendor', this.authService.getSkewName())
      .set('orderId', orderId)
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('userId', '' + this.authService.getServiceUserId());
    return this.foodAggregatorService.get('urbanpiper/get/rider-status', param, header, true);
  }

  public getShopDetails(): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get(this.getShopDetailsURL(), param, header, true);
  }

  public getAllOutletAggregatorWiseDetails(): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get('get-all-outlet-image', param, header, true);
  }

  private getShopDetailsURL(): string {
    if (window.location.pathname.includes('fa/sales-order')) {
      return 'get/configured-store';
    } else {
      return 'get/store-list';
    }
  }

  public getStoreConfiguration(): Observable<StoreConfig[]> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get('urbanpiper/get/store/toggle', param, header);
  }

  public getActivePlatformDetails(): Observable<ActivePlatform[]> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', '1');
    return this.foodAggregatorService.get('urbanpiper/get/active/platform', param, header);
  }

  public updateStoreConfiguration(body: any): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post('urbanpiper/update/store/toggle', param, header, body, false);
  }
  
  public getStoreDetailById(refId: string): Observable<StoreEdit> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('refId', refId);
    return this.foodAggregatorService.get('get/store/id/', param, header, true);
  }

  public updateStoreDetails(body: any): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post('update/store-detail', param, header, body, true);
  }

  public getCategory(outletSelected: string): Observable<CategoryHeader> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected)
      .set('pageIndex', '0')
      .set('pageSize', '10000');
    return this.foodAggregatorService.get('report/category', param, header);
  }

  public getItemToggle(outletSelected: string, category: string, searchTag: string): Observable<ItemToggle[]> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      // tslint:disable-next-line: max-line-length
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected)
      .set('searchTag', searchTag)
      .set('category', category);
    return this.foodAggregatorService.get('urbanpiper/get/item/toggle', param, header, true);
  }

  public getAggregators(outletId: string): Observable<ActivePlatform[]> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId);
    return this.foodAggregatorService.get('urbanpiper/get/active/platform', param, header, true);
  }

  public saveItemConfig(outletSelected: string, body: any): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected)
      .set('aggStatus', 'true');
    if (this.authService.getSkewCode().includes('739') || this.authService.getSkewCode().includes('740')) {
      return this.foodAggregatorService.post('map/item-jt', param, header, body, true);
    } else {
      return this.foodAggregatorService.post('urbanpiper/update/item/toggle', param, header, body, true);
    }
  }

   // post mapped food tag
   public uploadMappedFoodTag(body: any): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      return this.foodAggregatorService.post('urbanpiper/foodTag', param, header, body, true);;
  }
  public uploadImageUrbanpiper(outletSelected: string, body: any): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected)
    return this.foodAggregatorService.post('upload-image-aggregatorwise', param, header, body, false);
  }

  public deleteImageUrbanpiper(outletSelected: string, body: any): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected)
    return this.foodAggregatorService.post('delete-image-aggregatorwise', param, header, body, false);
  }
  public saveAllOutletImage(outletSelected: string, body: any, skuCode): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected)
      .set('skuCode', skuCode)
    return this.foodAggregatorService.post('upload-image-aggregatorwise-all', param, header, body, false);
  }

  // Update option toggle for urbanpiper
  updateOptionToggle(outletId, formObj) {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId);
    return this.foodAggregatorService.post('urbanpiper/update/option/toggle', param, header, formObj, true);
  }

  urbanPiperGetItems(body: any,outletId): Observable<any>{
    const header = new HttpHeaders({ timeout: `${30000}`}).set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', outletId)
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post('urbanpiper/get/items', param, header, body, true);
  }
  urbanpiperCsvImport(data: FormData, outletId): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${300000}` })
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
    .set('userId', '' + this.authService.getServiceUserId())
    .set('outletId', outletId)
    .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.postUpCsv('urbanpiper/save/all-items', param, headers, data, true)  
  }

  urbanpiperCsvExport(body: any, outletId): Observable<any> {
    const header = new HttpHeaders({ timeout: `${300000}` })
    .set('Accept', 'text/csv')
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
        .set('userId', '' + this.authService.getServiceUserId())
        .set('outletId', outletId)
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.postUpCsv('urbanpiper/get/items/downloadCSV', param, header, body, true);
  }

  urbanPiperSaveItems(body: any, outletId): Observable<any>{
    const header = new HttpHeaders({ timeout: `${30000}` })
    .set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', outletId)
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post('urbanpiper/save/items', param, header, body, true);
  }

  public pullAllDataService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` })
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get('do/all-data-sync', param, headers, false);
  }
  
  public getOutletDetailsForPOSMenuPublish(data): Observable<OutletDetail[]> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + 0)
      .set('orderNo', '' + '0')
      .set('vendor', this.authService.getSkewName())
      .set('adminCheck', data);
    return this.foodAggregatorService.get('get/configured-store', param, header, true);
  }

   // Get aggregator list details
   getAggregatorListDetails(formObj) {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post('urbanpiper/get/aggregator-list', param, header, formObj, true);
  }

    // Get menu publis list details
    getMenuPublishListDetails(formObj) {
      const header = this.header();
      const param = new HttpParams()
        .set('userId', '' + this.authService.getServiceUserId())
        .set('vendor', this.authService.getSkewName());
      return this.foodAggregatorService.post(`urbanpiper/get/data/menu-publish`, param, header, formObj, true);
    }

       // Get publish status list
   getPublishStatusList(formObj) {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post('urbanpiper/get/status-list', param, header, formObj, true);
  }

   // Get item wise menu publish list
   getItemMenuStatusList(formObj, value) {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post(`urbanpiper/get/menu-publish/${value}`, param, header, formObj, true);
  }

   // check data flush for urbanpiper
   checkDataFlushForUp(formObj){
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post(`urbanpiper/check-data-flush`, param, header, formObj, true);
  }

  // submit for menu publish
  submitForMenuPublish(formObj) {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post(`urbanpiper/menu/publish`, param, header, formObj, true);
  }

   // Get pos dats sync details
   getPOSDataSyncDetails(formObj) {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post(`get/data/pos-data-sync`, param, header, formObj, true);
  }

  // check web report status
  checkWebReportStausDetails(outletId, formObj) {
    const header = new HttpHeaders({ timeout: `${60000}` })
    .set('Content-Type', 'application/json').set('X-Auth-Token', this.authService.getAuthToken());
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId);
    return this.foodAggregatorService.post(`check/posHealth`, param, header, formObj, true);
  }

  // Post pos dats sync details
  postPOSDataSyncDetails(formObj) {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get(`do/all-data-sync`, param, header, true);
  }

  public resetSyncServiceLoader(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${60000}` })
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get('urbanpiper/reset-sync', param, headers, true);
  }

  public pushOrderPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` })
    .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get('push/order/pos', param, headers, false);
  }

  public upGetConfiguration(): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get('get/configured-store', param, header);
  }

  public upGetOutletDetails(): Observable<OutletDetail[]> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('skew', this.authService.getSkewCode()).set('userId', ''+this.authService.getServiceUserId());
    const param = new HttpParams().set('loginId', encodeURIComponent(this.authService.getEmailId())).set('skipHq', '1');
    return this.accountService.get('micro_service/store/details', param, header, true);
  }

  public getProduct(): Observable<Product[]> {
    return of(this.configData.urbanpiper.product);
  }

  public getConfig(outletId: number): Observable<UrbanpiperConfig> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + outletId)
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get('urbanpiper/get/configuration', param, header, true);
  }

  public testURL(url: string, product: string): Observable<any> {
    const header = this.header();
    const param = new HttpParams().set('url', window.btoa(url)).set('product', product)
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.get('store/test/domain-url', param, header);
  }

  public genOTP(url: string, domain: string): Observable<any> {
    const param = new HttpParams().set('url', window.btoa(url)).set('skewCode', this.authService.getSkewCode()).set('domain', domain);
    return this.ecommerceAPIService.get('store/regen/otp', param);
  }

  public saveConfig(body: any): Observable<any> {
    const header = this.header();
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.foodAggregatorService.post('urbanpiper/create/store', param, header, body, true);
  }

  // Ecommerce Services

  public riderTemperatureUpdate(body: any): Observable<any> {
    const param = new HttpParams()
      .set('vendor', this.authService.getSkewName())
      .set('userId', '' + this.authService.getServiceUserId());
      // setting Auth key
      const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authService.getAuthToken())
      .set('key', window.btoa(this.authService.getEmailId()));

      if (this.authService.getSkewCode() === "926") {
        return this.foodAggregatorService.post('direct/zomato/update/rider-temperature', param, header, body, true);
      } else {
        return this.foodAggregatorService.post('urbanpiper/update/rider-temperature', param, header, body, true);
      }
  }
  
  public CustomerSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/customer-sync', param, headers, false);
  }

  public resetSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('reset-sync', param, headers, false);
  }


  pullOfferDetailsFromPOS(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.post('v1/do/offer-sync', param, header, null, true);
  }
  
  public pushUrbanpiperDataOnlineService(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    param = param.append('outletId', outletSelected);
    return this.ecommerceAPIService.get('/urbanpiper/push-items', param, headers, false);
  }

  public getService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/salesorder-sync', param, headers, false);
  }

  public taxSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/tax-sync', param, headers, false);
  }

  public pushtaxSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('push/tax-online', param, headers, false);
  }

  public pullItemsPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/item-sync', param, headers, false);
  }

  public pushItemsOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('push/item-online', param, headers, false);
  }

  public pushUrbanpiperOrderOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('urbanpiper/push/order-status', param, headers, false);
  }

  public pullOrderStatusPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/order-sync', param, headers, false);
  }

  public pullModifierGroupMaster(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/modifier-group-sync', param, headers, false);
  }

  public pullModifierMaster(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/modifier-sync', param, headers, false);
  }

  public pullChargeMasterFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('/do/charge-sync', param, headers, false);
  }


  public pushSalesOrderStatusOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('/push/salesorder-online', param, headers, false);
  }

  public pushSalesOrderToPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('push/order/pos', param, headers, false);
  }

  public pullSalesOrderFromOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('/pull/salesorder-online', param, headers, false);
  }

  // tempFunctions

  public purchaseOrderSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected);
    return this.ecommerceAPIService.get('do/po-sync', param, headers, false);
  }

  public receiptNoteSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected);
    return this.ecommerceAPIService.get('do/sales-sync', param, headers, false);
  }

  public salesSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletSelected);
    return this.ecommerceAPIService.get('do/sales-sync', param, headers, false);
  }

  public syncDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('ids', body);
    return this.ecommerceAPIService.get('get/sync-status', param, header, true);
  }

  public pullSalesReturnFromOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('pull/sale-return-online', param, headers, false);
  }

  public pushSalesReturnToPos(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('push/sale-return-to-pos', param, headers, false);
  }

  public pullPendingSoQuantityFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/pending-so-qty-sync', param, headers, false);
  }

  public pullPaymentTypesFromPos(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('sync/payment-types', param, headers, false);
  }

  public SupplierSyncFromPOS(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('pull/supplier-from-pos', param, headers, false);
  }

  public supplierSyncOnline(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('push/supplier-online', param, headers, false);
  }

   // tempFunctions

   public categorySyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('do/category-sync', param, headers, false);
  }

  public pushcategorySyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('push/category-online', param, headers, false);
  }

  public pullSalesOrderStatusFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('/do/salesorder-sync', param, headers, false);
  }

  getAllMastersForFlush( selectedOutletID: any) {
    const headers = new HttpHeaders({ timeout: `${20000}` });
        const param = new HttpParams()
        .set('userId', '' + this.authService.getServiceUserId())
        .set('vendor', this.authService.getSkewName())
        .set('outletId', selectedOutletID ? selectedOutletID:this.authService.getSelectedOutletId());
        const header = new HttpHeaders().set('Content-Type', 'application/json')
          .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId()));
        return this.foodAggregatorService.post('v1/get-masters', param, header, null, true);
    }

    // Trigger OTP for Data flush
  triggerOTPForFlush(formTriggerData) {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
      const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authService.getAuthToken())
      .set('key', window.btoa(this.authService.getEmailId()));
      return this.foodAggregatorService.post('v1/trigger-otp', param, header, formTriggerData, true);
  }

    // Flush data for Data flush
    flushDataMasters(flushData) {
      const param = new HttpParams()
        .set('userId', '' + this.authService.getServiceUserId())
        .set('vendor', this.authService.getSkewName());
        const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authService.getAuthToken())
        .set('key', window.btoa(this.authService.getEmailId())); 
  
        return this.foodAggregatorService.post('v1/flush-data', param, header, flushData, true); 
    }


  // Get trouble shooting details
  getTroubleShootingDetails(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.post('v1/data-sync/troubleshoot', param, header, formObj, true);
  }


  pullItemImageFromGoAct(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authService.getAuthToken()).set('key', window.btoa(this.authService.getEmailId())).set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.post('v1/do/item-image-sync', param, header, null, true);
  }

  // sync config service

  private getHeader(): HttpHeaders {
    const httpHeaders = new HttpHeaders();
    return httpHeaders.set('Content-Type', 'application/json').set('X-Auth-Token', '4e248943-d818-4390-9919-4f796b737b7f');
  }

  public getSyncConfig(outletId: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', String(this.authService.getServiceUserId()))
        .set('vendor', this.authService.getSkewName())
        .set('outletId', outletId);
    return this.apiService.get(environment.faURL, 'get/sync-config', param, this.getHeader(), true);
  }

  public saveSyncConfig(body: any, outletId: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', String(this.authService.getServiceUserId()))
        .set('vendor', this.authService.getSkewName())
        .set('outletId', outletId);
    return this.apiService.post(environment.faURL, 'save/sync-config', param, this.getHeader(), body, true);  
}

public saveScheduleConfig(task: any, cronString: any, schedule: any): Observable<any> {
  const param = new HttpParams()
      .set('userId', String(this.authService.getServiceUserId()))
      .set('vendor', this.authService.getSkewName())
      .set('task', task)
      .set('cron_string', cronString)
      .set('scheduler', schedule);
  return this.apiService.post(environment.ecomURL, 'schedule', param, this.getHeader(), null, true);
}

public getSchedulerConfig(): Observable<any> {
  const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName());
  return this.ecommerceAPIService.get('get-scheduler-details', param, null,  true);
}


public removeScheduleConfig(task: any, schedule: any): Observable<any> {
  const header = new HttpHeaders().set('X-ZCSRF-TOKEN', 'csrfEcomParam='+this.cookieService.get('csrfEcomCookie'));
  const param = new HttpParams()
      .set('userId', String(this.authService.getServiceUserId()))
      .set('vendor', this.authService.getSkewName())
      .set('task', task)
      .set('scheduler', schedule);
  return this.apiService.post(environment.ecomURL, 'unschedule', param, header, null, true);
}

 // Get Unsync orders 
 public getUnSyncOrders(body): Observable<any> {
  const header = this.header();
  const param = new HttpParams()
    .set('userId', '' + this.authService.getServiceUserId())
    .set('vendor', body.vendor);
  return this.foodAggregatorService.get('get/unsync-alert', param, header, true);
}

public postCallCustomer(outletId: string, onlineReferenceNo: string, status: string): Observable<any> {
  const header = this.header();
  const param = new HttpParams()
    .set('userId', '' + this.authService.getServiceUserId())
    .set('vendor', this.authService.getSkewName())
    .set('outletId', outletId)
    .set('onlineReferenceNo', onlineReferenceNo)
    .set('reason', status);
  return this.foodAggregatorService.post('direct/call-swiggy', param, header, true);
}
}