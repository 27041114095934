<div class="bg">
  <div class="home-menu-container">
    <div class="home-menu container">
      <div class="home-menu-header">
        <!-- <img src="./assets/NewLogo.png" width="50"> -->
        <h5>GOFRUGAL Integrations</h5>
      </div>
      <nav class="nav home-menu">
        <a class="nav-link active" data-toggle="tab" href="#menu">Home</a>
        <a class="nav-link" data-toggle="tab" href="#menu1">My Add-ons</a>
        <a class="nav-link" data-toggle="tab" href="#menu2">All Add-ons</a>
      </nav>
    </div>

    <div class="btn-group logout-button">
      <button class="btn dropdown-toggle btn-md" type="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">{{uName}}</button>
      <div class="dropdown-menu">
        <a class="dropdown-item" (click)="logout()">Logout</a>
      </div>
    </div>
  </div>
  <div id="tab-area" class="tab-content container-fluid">

    <div id="menu" class="tab-pane fade container active show" *ngIf="addons">

      <div style="padding-bottom: 4%;" *ngIf="addons.installed.length">
        <h5>Your Addons</h5>
        <app-carousel [addons]='addons.installed' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div> 

      <div style="padding-bottom: 4%;" *ngIf="addons.newAddons.length">
        <h5>What's New</h5>
        <app-carousel [addons]='addons.newAddons' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div>

      <div style="padding-bottom: 4%;" *ngIf="addons.trending.length">
        <h5>Trending</h5>
        <app-carousel [addons]='addons.trending' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div>

    </div>


    <div id="menu1" class="tab-pane fade container" *ngIf="addons">

      <div style="padding-bottom: 4%;" *ngIf="addons.installedEcommerce.length">
        <h5>Ecommerce Addons</h5>
        <app-carousel [addons]='addons.installedEcommerce' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div>

      <div style="padding-bottom: 4%;" *ngIf="addons.installedLoyalty.length">
        <h5>Loyalty Addons</h5>
        <app-carousel [addons]='addons.installedLoyalty' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div>

      <div style="padding-bottom: 4%;" *ngIf="addons.installedPayment.length">
        <h5>Payment Addons</h5>
        <app-carousel [addons]='addons.installedPayment' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div>

    </div>


    <div id="menu2" class="tab-pane container fade" *ngIf="addons">

      <div style="padding-bottom: 4%;" *ngIf="addons.ecommerce.length">
        <h5>Ecommerce Addons</h5>
        <app-carousel [addons]='addons.ecommerce' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div>

      <div style="padding-bottom: 4%;" *ngIf="addons.loyalty.length">
        <h5>Loyalty Addons</h5>
        <app-carousel [addons]='addons.loyalty' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div>

      <div style="padding-bottom: 4%;" *ngIf="addons.payment.length">
        <h5>Payment Addons</h5>
        <app-carousel [addons]='addons.payment' (onClick)='expiredLicenseCheck($event)'></app-carousel>
      </div>

    </div>
  </div>
  <footer>&copy; 2021, &copy; GOFRUGAL technologies Pvt. Ltd. All Rights Reserved</footer>
</div>
<div id="modal01" class="gft-modal" *ngIf="(modalPopupClose)"
     [ngStyle]="{'display': modalPopupClose ? 'block' : 'none'}"
>
  <div style="color: #ffffff;display: flex;width: 77vw;justify-content: space-between;">
    <div></div>
    <div style="margin-top: -25px;margin-right: 35px;">
      <h5 style="cursor: pointer;font-size: 24px !important;" (click)="closeTrackingModal()">
        &#x2715;
      </h5>
    </div >
  </div>
  <div class="gft-modal-content gft-animate-zoom">
    <span style="font-size: 18px;font-family: Open Sans,Semibold,sans-serif;text-align: center;color: white;margin-bottom: 10px;margin-top: -10px;"></span>
    <div class="d-flex justify-content-center">
      <div style="position: relative;">
        <img [src]="src" style="height: 75vh;object-fit: contain;" alt="redirect">
        <div style="text-align: center; position: absolute; bottom: 4%; right: 20%; font-size: 18px; font-family: 'Open Sans', sans-serif; color: #877e7e;">
          {{'You will be redirected in '+ (countTimer) +' seconds'}}
        </div>
        <button  type="submit" class="btn btn-primary type"  style="text-align: center; position: absolute; bottom: 2%; right: 2%;font-weight: 600;" (click)="onClickknowMore()">
          Yes, Redirect Me
        </button>
      </div>
    </div>
  </div>
</div>
<!--<img src="./assets/celebration.gif" *ngIf="countTimer < 1 && modalPopupClose" alt="celebration" style="height: 98vh; position: absolute; width: 97vw; top: 0; z-index: 21" >-->

<app-confetti-cannon class="position-absolute"></app-confetti-cannon>
