import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, tap, timeout } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { UiCommonService } from '../../shared/service/uiCommon.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private uiCommonService: UiCommonService
  ) { }

  // tslint:disable-next-line: max-line-length
  get(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.get(
      baseURL + `${path}`,
      { headers: header, params: param }
    ).pipe(
      catchError(this.handleError('Get')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  // tslint:disable-next-line: max-line-length
  put(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.put(
      baseURL + `${path}`,
      JSON.stringify(body),
      { headers: header, params: param }
    ).pipe(
      catchError(this.handleError('Put')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  // tslint:disable-next-line: max-line-length
  post(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.post(
      baseURL + `${path}`,
      JSON.stringify(body),
      { headers: header, params: param}
    ).pipe(
      catchError(this.handleError('Post')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  // tslint:disable-next-line: max-line-length
  postFormData(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    // let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;boundary=' + Math.random());
    headers.append('Accept', 'application/json');
    // headers.delete('Accept', 'application/json')
    if (showLoader) {
      this.uiCommonService.showLoader('blocking');
    }
    return this.http.post(
      baseURL + `${path}`,
      body,
      { headers: headers, params: param }
    ).pipe(
      timeout(30000),
      catchError(this.handleError('Post')),
      finalize(() => { this.uiCommonService.hideLoader(); })
    );
  }

  // tslint:disable-next-line: max-line-length
  delete(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.delete(
      baseURL + `${path}`,
      { params: param, headers: header }
    ).pipe(
      catchError(this.handleError('Delete')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  postFormBulkUploadImageData(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    // let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;boundary=' + Math.random());
    headers.append('Accept', 'application/json');
    // headers.delete('Accept', 'application/json')
    if (showLoader) {
      this.uiCommonService.showLoader('blocking');
    }
    return this.http.post(
      baseURL + `${path}`,
      body,
      { headers: headers, params: param }
    ).pipe(
      timeout(30000),
      catchError(this.handleError('Post')),
      finalize(() => { })
    );
  }

  // Used for pdf in amazon order processing screen
  // tslint:disable-next-line: max-line-length
  postPDF(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.post(
      baseURL + `${path}`,
      body,
      { headers: header, params: param }
    ).pipe(
      catchError(this.handleError('Post')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  getIp(header: HttpHeaders = new HttpHeaders()): Observable<any> {
    // return this.http.get<any>('https://geolocation-db.com/json/')
    //   .pipe(
    //     catchError(err => {
    //       return throwError(err);
    //     }),
    //     tap(response => { })
    //   );

    // return this.http.get('https://api.ipify.org/?format=json')
    //   .pipe(
    //     catchError(this.handleError)
    //   );

    return this.http.get(
      `https://api.ipify.org/?format=json`
    ).pipe(
      catchError(this.handleError('Get')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  postMultipartFile(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: FormData, showLoader?: Boolean): Observable<any> {
    let headers = new HttpHeaders({ timeout: `${300000}` });
    headers.append('Content-Type', 'multipart/form-data; boundary='+ Math.random());
    headers.append('Accept', 'application/json');
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.post(
      baseURL + `${path}`,
      body,
      { headers: headers, params: param }
    ).pipe(
      catchError(this.handleError('Post')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  getText(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.get(
      baseURL + `${path}`,
      { headers: header, params: param,  responseType: 'text'}
    ).pipe(
      catchError(this.handleError('Get')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  postUpCsv(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(),body: Object = {}, showLoader?: Boolean): Observable<any> {
    let headers = new HttpHeaders({ timeout: `${300000}` });
    headers.append('Content-Type', 'multipart/form-data; boundary='+ Math.random());
    headers.append('Accept', 'application/json');
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.post(
      baseURL + `${path}`,
      body,
      { headers: header, params: param,  responseType: 'text', observe: 'response' }
    ).pipe(
      catchError(this.handleError('Post')),
      finalize(() => this.notify.changeProgress(false))
    );
  }
  
  getTextAndJson(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.get(
      baseURL + `${path}`,
      { headers: header, params: param, responseType: 'text', observe: 'response' }
    ).pipe(
      catchError(this.handleError('Get')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  getXlsxFile(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.get(
      baseURL + `${path}`,
      { headers: header, params: param, responseType: 'blob', observe: 'response' }
    ).pipe(
      catchError(this.handleError('Get')),
      finalize(() => this.notify.changeProgress(false))
    );
  }

  getNew(baseURL: string, path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    if (showLoader) {
      this.notify.changeProgress(true);
    }
    return this.http.get(
      baseURL + `${path}`,
      { headers: header, params: param, withCredentials: true }
    ).pipe(
      catchError(this.handleError('Get')),
      finalize(() => this.notify.changeProgress(false))
    );
  }
}
